import { submitAnswer1 } from '../../../../api/extensions/game/game1';
import { Game1 } from '../../../../api/firestoreTypes';
import {
  SwitchInstructionColor,
  SwitchInstructionLogo,
} from '../../../../lib/instructionSettings';
import useLocalStorage from '../../../../lib/useLocalStorage';
import * as Types from '../../../../types';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/TimeGauge';
import StageManager from '../../stageProviders/StageManager';
import AchievedPanel from './AchievedPanel';
import DraggableArea from './DraggableArea';
import NewlyAchievedWindow from './NewlyAchievedWindow';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export type Target = Types.Stage1Target;

interface Stage1Props {}

const Stage1: React.FC<Stage1Props> = () => {
  const dispatch = useDispatch();
  const [submission, setSubmission] = useState<Game1>({
    answers: [],
  });
  const navigate = useNavigate();
  const [ansStr, setAnsStr] = React.useState('');
  const [allTargets, setAllTargets] = React.useState<Target[]>([]);
  const achievedTargetsLoaded = React.useRef<boolean>(false);
  const [achievedTargets, setAchievedTargets] = React.useState<Target[]>([]);
  const [newlyAchievedTargets, setNewlyAchievedTargets] = React.useState<
    Target[]
  >([]);

  // 新規解放がない
  const [noNewTargetAchieved, setNoNewTargetAchieved] =
    React.useState<boolean>(false);
  // 不正入力
  const [ansInvalid, setAnsInvalid] = React.useState<boolean>(false);

  const onSubmitAnswer1Error = React.useCallback(() => {
    setAnsInvalid(true);
  }, [setAnsInvalid]);

  const resetErrors = React.useCallback(() => {
    setAnsInvalid(false);
    setNoNewTargetAchieved(false);
  }, [setAnsInvalid, setNoNewTargetAchieved]);

  const findTargetById = React.useCallback(
    (id: number) => allTargets.find(v => v.targetId === id),
    [allTargets]
  );

  // 獲得済みターゲットの情報
  React.useEffect(() => {
    console.log('new target', allTargets);
    if (
      submission?.answers === undefined ||
      submission.answers.length === 0 ||
      allTargets.length === 0
    ) {
      return;
    }

    const lastAns = submission.answers[submission.answers.length - 1];

    const achievedIds = Array.from(
      new Set(submission.answers.map(ans => ans.achieved).flat())
    );

    const uniqAchievedTargets = achievedIds
      .map(id => findTargetById(id))
      .filter((target): target is Target => target !== undefined);

    // ページ表示時の初回読み込みだけ、獲得ウィンドウ表示しない
    if (!achievedTargetsLoaded.current) {
      achievedTargetsLoaded.current = true;
      setAchievedTargets(uniqAchievedTargets);
      return;
    }
    setAchievedTargets(achievedTargets => {
      const _newlyAchievedTargets = uniqAchievedTargets.filter(
        target =>
          achievedTargets.findIndex(t => t.targetId === target.targetId) === -1
      );
      if (_newlyAchievedTargets.length > 0) {
        // 新規達成
        setNewlyAchievedTargets(_newlyAchievedTargets);
      } else if (lastAns.achieved.length > 0) {
        // 達成あり、しかし新規なし
        setNoNewTargetAchieved(true);
      } else {
        // なにもなし
        setAnsInvalid(true);
      }
      return uniqAchievedTargets;
    });
  }, [submission, findTargetById, allTargets, setAchievedTargets]);

  // 送信
  const onSend = React.useCallback(() => {
    resetErrors();

    // 最初の送信時まで過去の提出がloadされてなければ、されたことにしておく
    achievedTargetsLoaded.current = true;

    const submit = submitAnswer1({
      answer: ansStr,
    });

    setAllTargets(prev => {
      return [...prev, ...submit.targets];
    });
    setSubmission(prev => {
      return {
        answers: [...prev.answers, submit.answer],
      };
    });
  }, [ansStr, dispatch, resetErrors, submitAnswer1]);

  const onCloseNewlyAchievedWindow = React.useCallback(() => {
    setNewlyAchievedTargets([]);
  }, []);

  const [eventId] = useLocalStorage<string>(':eventId:', '');
  const onSkip = useCallback(() => {
    navigate(`/events/${eventId}/stages/`);
    return;
  }, [navigate]);
  const duration = 6 * 60;
  return (
    <StageManager stageId={1} finishOnTimeUp>
      <CommonBG>
        <Wrapper>
          <TimeGauge
            timerKey={'stage1'}
            duration={duration}
            stageName={''}
            logo={SwitchInstructionLogo}
            color={SwitchInstructionColor}
            onSkip={onSkip}
            onRefreshDuration={(time: number) => {
              return;
            }}
            onBack={() => {
              navigate(`/events/${eventId}/stages/`);
              return;
            }}
            onFinished={() => {
              navigate(`/events/${eventId}/stages/`);
              return;
            }}
          />
          <DraggableArea
            errorMessage={
              (ansInvalid && '等式が間違っています') ||
              (noNewTargetAchieved && 'すでにターゲットを解放済みです') ||
              ''
            }
            resetErrors={resetErrors}
            setAnsStr={setAnsStr}
            onSend={onSend}
            achievedTargets={achievedTargets}
          />
          <AchievedPanel achievedTargets={achievedTargets} />
          <NewlyAchievedWindow
            newlyAchievedTargets={newlyAchievedTargets}
            onClose={onCloseNewlyAchievedWindow}
          />
        </Wrapper>
      </CommonBG>
    </StageManager>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
`;

export default Stage1;
