import { Stage1Target } from '../../types';
import { Game1 } from '../firestoreTypes';
import {
  collateValues,
  answer as stage1Answer,
  target as stage1Target,
} from './stage1';
import _ from 'lodash';

export default class Answer1Handler {
  achievedStage1Target: number[] = [];

  static validateAnswer1(inputAnswer: string) {
    // https://github.com/riddler-co-jp/dentsu-training-platform-server/wiki/Stage1--answer-format
    const re =
      /^(([0-6pm][0-7])(,[0-6pm][0-7])*)?\|(([0-6pm][0-7])(,[0-6pm][0-7])*)?$/;
    return re.test(inputAnswer);
  }

  // 最小角にする(mは1として扱う)
  unifyAnswer1(inputAnswer: string) {
    const splitAnswer = inputAnswer.split('|');
    const splitLeft = splitAnswer[0].split(',');
    const splitRight = splitAnswer[1].split(',');

    const outputLeft = this.minimizeAnswer1(splitLeft);
    const outputRight = this.minimizeAnswer1(splitRight);

    return { left: outputLeft, right: outputRight };
  }

  minimizeAnswer1(input: string[]) {
    const output: string[] = [];
    for (const v of input) {
      const result = this.minimizeAnswer1Element(v);
      output.push(result);
    }
    return output;
  }

  minimizeAnswer1Element(input: string) {
    if (input === '') {
      return '';
    }

    const tile = input.split('');
    const sym2 = ['0', '2', '5'];

    const sym2pair = ['0', '1', '2', '3', '0', '1', '2', '3'];
    const sym4pair = ['0', '1', '0', '1', '0', '1', '0', '1'];
    const minus = ['2', '3', '0', '1', '2', '3', '0', '1'];

    if (sym2.includes(tile[0])) {
      const find = sym2pair.find((v, k) => String(k) === tile[1]);
      if (find) {
        return tile[0] + find;
      }
    }
    if (tile[0] === 'p') {
      const find = sym4pair.find((v, k) => String(k) === tile[1]);
      if (find) {
        return tile[0] + find;
      }
    }
    if (tile[0] === '1') {
      const find = sym2pair.find((v, k) => String(k) === tile[1]);
      if (find) {
        if (find === '2') {
          this.achievedStage1Target.push(8);
        }
        return tile[0] + find;
      }
    }
    if (tile[0] === 'm') {
      const find = minus.find((v, k) => String(k) === tile[1]);
      if (find) {
        if (find === '0') {
          this.achievedStage1Target.push(8);
        }
        return `1${find}`;
      }
    }
    return tile[0] + tile[1];
  }

  // 特殊正解リスト
  collateAnswer1(inputLeft: string[], inputRight: string[]) {
    const left = inputLeft.join(',');
    const right = inputRight.join(',');
    const inputAnswer = `${left}|${right}`;

    const object = stage1Answer.find(ans => ans.answer.includes(inputAnswer));

    if (!object) {
      return false;
    }
    this.achievedStage1Target.push(...object.target);

    console.log('collateAnswer', inputLeft, inputRight);
    return true;
  }

  getAnswer1Target() {
    const achievedTarget = this.getAnswer1TargetList();

    const object: Stage1Target[] = [];
    achievedTarget.forEach(x => {
      const target = stage1Target.find(t => t.targetId === x);
      if (!target) {
        return;
      }
      object.push(target);
    });

    return object;
  }

  getAnswer1TargetList() {
    // 重複削除
    const achievedTarget = this.achievedStage1Target.filter(
      (x, i, self) => self.indexOf(x) === i
    );

    return achievedTarget;
  }

  // 以前解放されたターゲットを除く
  removeDuplicate(data: Game1) {
    const dataAnswers = data.answers || [];
    const prevAchievedTargets = dataAnswers
      .map(ans => ans.achieved || [])
      .flat();
    this.achievedStage1Target = this.achievedStage1Target.filter(
      x => !prevAchievedTargets.includes(x)
    );
  }

  calculateAnswer1(inputLeft: string[], inputRight: string[]) {
    if (inputLeft.length === 0 || inputRight.length === 0) {
      this.achievedStage1Target = [];
      return false;
    }
    if (inputLeft.length === 1 && inputLeft[0] === '') {
      this.achievedStage1Target = [];
      return false;
    }
    if (inputRight.length === 1 && inputRight[0] === '') {
      this.achievedStage1Target = [];
      return false;
    }

    const stringLeft = this.convertAnswer1(inputLeft);
    const stringRight = this.convertAnswer1(inputRight);

    if (!stringLeft || !stringRight) {
      this.achievedStage1Target = [];
      return false;
    }

    const hasE = stringLeft.includes('e') || stringRight.includes('e');

    const convertedStringLeft = hasE
      ? stringLeft.replace(/[01234569e]+/g, match => '0x' + match)
      : stringLeft;
    const convertedStringRight = hasE
      ? stringRight.replace(/[01234569e]+/g, match => '0x' + match)
      : stringRight;

    console.log(convertedStringLeft, convertedStringRight);
    const left = this.calculate(convertedStringLeft);
    const right = this.calculate(convertedStringRight);

    const correct = left !== null && right !== null && left === right;
    if (correct && hasE) {
      this.achievedStage1Target.push(25);
      console.log('correct', convertedStringLeft, convertedStringRight);
    }

    // 演算できなかったらターゲットを消してfalseを返す
    if (!correct) {
      console.log(stringLeft, stringRight);
      // 8進数チェック
      if (stringLeft.match(/^[+\\-]?\d+$/) && stringRight.match(/^[+-]?\d+$/)) {
        console.log('checkOctal');
        const octString = stringLeft.includes('0') ? stringLeft : stringRight;
        const opponentString = stringLeft.includes('0')
          ? stringRight
          : stringLeft;
        if (octString.match(/^[+\\-]?0\d+$/)) {
          const convertedOctString = octString.replace('0', '0o');
          if (
            this.calculate(convertedOctString) ===
            this.calculate(opponentString)
          ) {
            this.achievedStage1Target.push(32);
            this.achievedStage1Target.push(13);
            this.checkDigit(opponentString);
            this.checkDigit(convertedOctString.replace('0o', ''));
          } else {
            this.achievedStage1Target = [];
            return false;
          }
        } else {
          this.achievedStage1Target = [];
          return false;
        }
      } else if (hasE) {
        // 指数
        const eString = stringLeft.includes('e') ? stringLeft : stringRight;
        const opponentString = stringLeft.includes('e')
          ? stringRight
          : stringLeft;
        if (!/[+-]?[01234569]+e[01234569]/.test(eString)) {
          this.achievedStage1Target = [];
          return false;
        }
        if (this.calculate(eString) !== this.calculate(opponentString)) {
          this.achievedStage1Target = [];
          return false;
        }
        console.log('add exp target');
        eString.split('e').forEach(str => this.checkDigit(str));
        this.checkDigit(opponentString);
        this.achievedStage1Target.push(33);
      } else {
        // 絶対値
        if (
          [...stringLeft.matchAll(/1/g)].length === 2 ||
          [...stringRight.matchAll(/1/g)].length === 2
        ) {
          const absString = (
            [...stringLeft.matchAll(/1/g)].length === 2
              ? stringLeft
              : stringRight
          )
            .replace('1', 'Math.abs(')
            .replace('1', ')');
          const absValue = this.calculate(absString);
          const opponentString =
            [...stringLeft.matchAll(/1/g)].length === 2
              ? stringRight
              : stringLeft;
          const opponentValue = this.calculate(opponentString);

          const absCorrect =
            absValue !== null &&
            opponentValue !== null &&
            absValue === opponentValue;
          if (!absCorrect) {
            this.achievedStage1Target = [];
            return false;
          }
          console.log('add abs target');
          this.checkDigit(stringLeft.replace(/1/g, ''));
          this.checkDigit(stringRight.replace(/1/g, ''));
          this.achievedStage1Target.push(29);
        } else {
          this.achievedStage1Target = [];
          return false;
        }
      }
    } else {
      this.checkDigit(stringLeft);
      this.checkDigit(stringRight);
    }

    if (hasE) {
      this.achievedStage1Target.push(13);
    }

    this.checkNegativeNumber(stringLeft, stringRight);
    this.checkFormula(stringLeft, stringRight);

    return true;
  }

  calcAndCollateAnswer(calcInput: string[], collateInput: string[]) {
    if (calcInput.length === 0) {
      this.achievedStage1Target = [];
      return false;
    }
    if (calcInput.length === 1 && calcInput[0] === '') {
      this.achievedStage1Target = [];
      return false;
    }
    const stringCalcInput = this.convertAnswer1(calcInput);

    if (!stringCalcInput) {
      this.achievedStage1Target = [];
      return false;
    }
    if (stringCalcInput.includes('e')) {
      this.achievedStage1Target = [];
      return false;
    }

    const calcResult = this.calculate(stringCalcInput);

    const stringCollateInput = collateInput.join(',');

    const object = collateValues.find(ans => ans.code === stringCollateInput);

    console.log(stringCalcInput, calcResult, stringCollateInput, object);
    if (!object) {
      this.achievedStage1Target = [];
      return false;
    }
    if (calcResult === object.value) {
      this.achievedStage1Target.push(...object.targets);
      this.checkDigit(stringCalcInput);

      console.log('calcAndColateInput', stringCalcInput, stringCollateInput);
      return true;
    }
    this.achievedStage1Target = [];
    return false;
  }

  // 文字列の数式を計算
  // eslint-disable-next-line class-methods-use-this
  calculate(input: string) {
    let result = 0;
    try {
      // eslint-disable-next-line no-new-func
      result = Function(`"use strict";return(${input})`)();
    } catch (e) {
      console.log(e);
      return null;
    }
    return result;
  }

  // 負の数
  checkNegativeNumber(left: string, right: string) {
    if (left.indexOf('-') === 0 || right.indexOf('-') === 0) {
      this.achievedStage1Target.push(12);
    }
  }

  // 両辺に数式
  checkFormula(left: string, right: string) {
    const reg = /([01234569e])+[+*-/]([01234569e])+/;
    const l = left.match(reg);
    const r = right.match(reg);

    if (l && r) {
      this.achievedStage1Target.push(11);
    }
  }

  // ~桁の数字系
  checkDigit(input: string) {
    const formula = input.split(/[+*-/]/);

    formula.forEach(v => {
      const len = v.length;
      switch (len) {
        case 1:
          this.achievedStage1Target.push(3);
          break;
        case 2:
          this.achievedStage1Target.push(4);
          break;
        case 3:
          this.achievedStage1Target.push(6);
          break;
        case 4:
          this.achievedStage1Target.push(10);
          break;
        default:
          break;
      }
    });
  }

  // 演算できる形式に直す
  convertAnswer1(inputAnswer: string[]) {
    let ans = '';
    for (const v of inputAnswer) {
      const result = this.convertAnswer1String(v);
      if (!result) {
        return null;
      }
      ans += result;
    }
    if (/[+\\-]?[01234569e]+([+\\-\\*/][01234569e]+)*/.test(ans)) {
      return ans;
    }
    return null;
  }

  convertAnswer1String(inputAnswer: string) {
    const tile = inputAnswer.split('');

    switch (tile[0]) {
      case '0':
      case '2':
      case '4':
      case '5':
        if (tile[1] === '0') {
          return tile[0];
        }
        break;
      case '3':
        switch (tile[1]) {
          case '0':
            return tile[0];
          case '4':
            return 'e';
        }
        break;
      case '1':
        switch (tile[1]) {
          case '0':
            return tile[0];
          case '1':
            this.achievedStage1Target.push(9);
            return '/';
          case '2':
            this.achievedStage1Target.push(2);
            return '-';
          default:
            break;
        }
        break;
      case '6':
        switch (tile[1]) {
          case '0':
            return tile[0];
          case '4':
            this.achievedStage1Target.push(7);
            return '9';
          default:
            break;
        }
        break;
      case 'p':
        switch (tile[1]) {
          case '0':
            this.achievedStage1Target.push(1);
            return '+';
          case '1':
            this.achievedStage1Target.push(5);
            return '*';
          default:
            break;
        }
        break;
      default:
        break;
    }

    return null;
  }
}
