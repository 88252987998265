import Colors from '../../styles/colors';
import * as React from 'react';
import styled, { css } from 'styled-components';

interface SpinnerProps {
  color?: string;
  size?: string;
  widthRatio?: number;
}
const Spinner: React.FC<SpinnerProps> = props => {
  const color = props.color ?? Colors.primary;
  const size = props.size ?? '8rem';
  const widthRatio = props.widthRatio ?? 0.1;

  return (
    <SpinnerBody color={color} size={size} widthRatio={widthRatio}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </SpinnerBody>
  );
};

interface SpinnerBodyProps {
  color: string;
  size: string;
  widthRatio: number;
}

const getSize = (p: SpinnerBodyProps) => p.size;
const getBorderWidth = (p: SpinnerBodyProps) =>
  `calc(${p.size} * ${p.widthRatio})`;

const getBorderStyle = (p: SpinnerBodyProps) => {
  const { color } = p;

  return css`
    border: ${getBorderWidth(p)} solid ${color};
    border-radius: 50%;
    border-color: ${color} transparent transparent transparent;
  `;
};

const SpinnerBody = styled.div`
  width: ${getSize};
  height: ${getSize};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${getSize};
    height: ${getSize};
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    ${getBorderStyle}
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
