import {
  InstructionColor,
  CommInstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRemainingTime } from '../../../../redux/selectors/timeSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/TimeGauge';
import GameAnswererView from './GameAnswererView';
import { Stage5Problem } from './data/data';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface GameAnswererProps {
  problem: Stage5Problem;
  showingAnswer: boolean;
  searchId: number;
  onChangeAnswer: (answer: number | null) => void;
  onSkip: () => void;
}

const GameAnswerer: React.FC<GameAnswererProps> = ({
  problem,
  showingAnswer,
  onChangeAnswer,
  onSkip,
}) => {
  const [currentAnswer, setCurrentAnswer] = React.useState<number | null>(null);
  const [eventId] = useLocalStorage<string>(':eventId:', '');
  const remainingTime = useRemainingTime() ?? 0;
  const navigate = useNavigate();
  const updateAnswer = (ans: number) => {
    if (showingAnswer || remainingTime < 1) {
      return;
    }
    setCurrentAnswer(ans);
  };

  React.useEffect(() => {
    onChangeAnswer(currentAnswer);
  }, [currentAnswer, onChangeAnswer]);

  const duration = 30;

  return (
    <CommonBG>
      <TimeGauge
        timerKey={'stage:comm:' + problem.index}
        duration={duration}
        stageName={''}
        logo={CommInstructionLogo}
        color={InstructionColor}
        onSkip={onSkip}
        onRefreshDuration={(time: number) => {
          return;
        }}
        onBack={() => {
          navigate(`/events/${eventId}/stages/`);
          return;
        }}
      />
      <GameAnswererView
        onUpdate={updateAnswer}
        currentAnswer={currentAnswer}
        showingAnswer={showingAnswer}
        problem={problem}
      />
    </CommonBG>
  );
};

export default GameAnswerer;
