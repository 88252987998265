import RightPanelTextImg from '../../../../static/png/stage1/Stage1_RightPanelText.png';
import ArrowRightWhiteImg from '../../../../static/svg/ArrowRightWhite.svg';
import KaihousuuImg from '../../../../static/svg/Kaihousuu.svg';
import Colors from '../../../../styles/colors';
import * as Types from '../../../../types';
import React from 'react';
import styled from 'styled-components';

interface AchievedPanelProps {
  achievedTargets: Types.Stage1Target[];
}

const AchievedPanel: React.FC<AchievedPanelProps> = props => {
  const { achievedTargets } = props;
  const [showingAchievedPanel, setShowingAchievedPanel] =
    React.useState<boolean>(false);
  return (
    <AchievedPanelWrapper active={showingAchievedPanel}>
      <AchievedList>
        {achievedTargets.map(t => (
          <AchievedItem key={t.targetId}>{t.name}</AchievedItem>
        ))}
      </AchievedList>

      <ArrowBox onClick={() => setShowingAchievedPanel(!showingAchievedPanel)}>
        <Arrow active={showingAchievedPanel} />
        <RightPanelText />
      </ArrowBox>
    </AchievedPanelWrapper>
  );
};

interface AchievedPanelWrapperProps {
  active: boolean;
}
const AchievedPanelWrapper = styled.div`
  position: fixed;
  top: 0;
  right: ${(p: AchievedPanelWrapperProps) => (p.active ? '0' : '-39.2rem')};
  height: 100%;
  width: 40rem;
  z-index: 2;
  background-color: ${Colors.gray8_op090};
  padding: 3rem 0 0;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;

  &:hover {
    right: ${(p: AchievedPanelWrapperProps) => (p.active ? '0' : '-38.2rem')};
  }
`;

const AchievedList = styled.div`
  width: 100%;
  padding: 3rem 2rem;
  height: calc(100% - 12rem);
  box-sizing: border-box;
  text-align: justify;
  overflow-y: scroll;
`;

export const AchievedItem = styled.span`
  display: inline-block;
  width: 15rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: center;
  padding: 1rem 0;
  background-color: ${Colors.gray2};
  border: ${Colors.gray6} 1px solid;
  border-radius: 1rem;
  margin-bottom: 1.7rem;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
`;

const Kaihousuu = styled.img.attrs({
  src: KaihousuuImg,
})`
  position: absolute;
  bottom: 3rem;
  height: 3.4rem;
`;

const KaihouNum = styled.div`
  position: absolute;
  height: 7rem;
  width: 14rem;
  bottom: 2.6rem;
  right: 3rem;
  font-size: 7rem;
  line-height: 7rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.2rem;

  color: ${Colors.white};
`;

const ArrowBox = styled.div`
  position: absolute;
  bottom: 3rem;
  height: 3.4rem;
  padding-left: 3.4rem;
  right: 100%;
  background-color: ${Colors.gray8};
`;

// prettier-ignore
const Arrow = styled.img.attrs({
  src: ArrowRightWhiteImg,
})`
  height: 47%;
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%) rotate(${(p: AchievedPanelWrapperProps) => (p.active ? '0deg' : '180deg')});
  transition: 0.3s;
`;

const RightPanelText = styled.img.attrs({
  src: RightPanelTextImg,
})`
  height: 2rem;
  margin: 0.7rem 1rem;
`;

export default AchievedPanel;
