import { CommContext } from '../../../../hoc/CommContext';
import { InstructionText } from '../../../../lib/instructionSettings';
import BtnBg from '../../../../static/png/btn_bg.png';
import BtnDisableBg from '../../../../static/png/btn_disable_bg.png';
import Icon_Player3 from '../../../../static/png/player_3.png';
import UserIcon from '../../../uiElements/UserIcon';
import {
  Button as _Button,
  CommonBG,
  TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface PlayerSelectorProps {
  onStart: (playerId: number) => void;
}

export type pair = {
  id: number;
  name: string;
};

export type tuple = {
  id: number;
  title: string;
  explanation: string;
};

export type Item = {
  questionCount: number;
  title: string;
  features: pair[];
  featuresRev: pair[];
  ideas: tuple[];
};

const PlayerSelector: React.FC<PlayerSelectorProps> = props => {
  const text = InstructionText;

  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const onClick = React.useCallback(() => {
    console.log('clicked');
    navigate(`/${trainingId}`);
  }, [navigate, trainingId]);

  const [phase, setPhase] = useState('playerId');
  const ctx = React.useContext(CommContext);
  const playerNum = 3;
  const [playerId, setPlayerId] = useState(0);

  return (
    <Wrap>
      <CommonBG>
        {phase === 'playerId' && (
          <Content>
            <TextWindow bracket>
              グループ内で、それぞれ別のプレイヤー番号を選んでください。
            </TextWindow>
            <SelectButtons>
              <li
                onClick={() => {
                  setPlayerId(1);
                  setPhase('waiting');
                }}
              >
                <span>
                  <UserIcon
                    maxPlayers={playerNum}
                    player={0}
                    width={280}
                    height={248}
                  />
                </span>
                <p>
                  プレイヤー<b>1</b>
                </p>
              </li>
              <li
                onClick={() => {
                  setPlayerId(2);
                  setPhase('waiting');
                }}
              >
                <span>
                  <UserIcon
                    maxPlayers={playerNum}
                    player={1}
                    width={280}
                    height={248}
                  />
                </span>
                <p>
                  プレイヤー<b>2</b>
                </p>
              </li>
              <li
                onClick={() => {
                  setPlayerId(3);
                  setPhase('waiting');
                }}
              >
                <span>
                  <UserIcon
                    maxPlayers={playerNum}
                    player={2}
                    width={280}
                    height={248}
                  />
                </span>
                <p>
                  プレイヤー<b>3</b>
                </p>
              </li>
            </SelectButtons>
          </Content>
        )}
        {phase === 'waiting' && (
          <Content>
            <TextWindow bracket>
              全員で同時にスタートボタンをクリックしてください
            </TextWindow>
            {ctx && ctx.comm1.playerId && (
              <SelectButtons disabled>
                <li>
                  <span>
                    <img src={Icon_Player3} width={280} height={248} />
                  </span>
                  <p>
                    <b>{playerNum}</b>人でプレイ
                  </p>
                </li>
                <li>
                  <span>
                    <UserIcon
                      maxPlayers={playerNum}
                      player={playerId - 1}
                      width={280}
                      height={248}
                    />
                  </span>
                  <p>
                    プレイヤー<b>{playerId}</b>
                  </p>
                </li>
              </SelectButtons>
            )}
            <Buttons>
              <Button
                color='negative'
                size='large'
                onClick={() => {
                  setPhase('playerId');
                }}
              >
                戻る
              </Button>
              <Button
                color='positive'
                size='large'
                onClick={() => {
                  props.onStart(playerId);
                }}
              >
                スタート
              </Button>
            </Buttons>
          </Content>
        )}
      </CommonBG>
    </Wrap>
  );
};

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
`;

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  max-width: 900px;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 30px;

  > div:first-child {
    white-space: nowrap;
  }
`;

interface ButtonProps {
  disabled?: boolean;
}

const SelectButtons = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0;

  li {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    list-style: none;
    background: #7a4aff;
    text-align: center;
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
    margin-right: 50px;

    > p {
      height: 107px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: pre-wrap;

      b {
        font-size: 4.2rem;
        margin-bottom: 10px;
        margin-right: 2px;
      }
    }

    span {
      width: 100%;
      display: block;
      background: url(${BtnBg}) repeat;
      background-size: 16px 16px;
      font-size: 0;
    }

    &:active {
      opacity: 0.8;
    }

    &:last-child {
      margin-right: 0;
    }

    &:last-child:after {
      display: none;
    }

    ${(p: ButtonProps) => {
      return p.disabled == true
        ? `
          span {
            background: url(${BtnDisableBg}) repeat;
            background-size: 16px 16px;
          }
          background: #868E96;
          pointer-events: none;
        `
        : `
          box-shadow: 0px 5px 0px #6338db;
      `;
    }}
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export default PlayerSelector;
