import Colors from '../../../styles/colors';
import Spinner from '../Spinner';
import {
  ButtonSize,
  ButtonSizeStyleSet,
  ButtonStateStyleSet,
  ButtonStyleGenerator,
  MainButtonColor,
  MainButtonColorBasedDict,
  MainButtonProps,
} from './buttonConfigs';
import * as React from 'react';
import styled, { css } from 'styled-components';

const spinnerSizes: Record<ButtonSize, string> = {
  large: '4rem',
  medium: '2.6rem',
};

const MainButton: React.FC<MainButtonProps> = props => {
  return (
    <MainButtonWrapper {...props}>
      {props.children}
      {props.loading && (
        <SpinnerWrapper>
          <Spinner
            size={spinnerSizes[props.size]}
            color={Colors.white}
            widthRatio={0.2}
          />
        </SpinnerWrapper>
      )}
    </MainButtonWrapper>
  );
};

const styles: MainButtonColorBasedDict<ButtonStateStyleSet> = {
  positive: {
    default: css`
      background-color: ${Colors.primary};
      color: ${Colors.white};
    `,
    active: css`
      opacity: 0.8;
    `,
    disabled: css`
      opacity: 0.5;
      pointer-events: none;
    `,
  },
  negative: {
    default: css`
      background-color: ${Colors.gray5};
      color: ${Colors.white};
    `,
    active: css`
      opacity: 0.8;
    `,
    disabled: css`
      opacity: 0.5;
      pointer-events: none;
    `,
  },
};

const shadowColors: Record<MainButtonColor, string> = {
  positive: Colors.accent,
  negative: Colors.gray8,
};

const shadowHeights: Record<ButtonSize, [string, string]> = {
  large: ['1rem', '0.5rem'],
  medium: ['0.7rem', '0rem'],
};

const sizeStyles: ButtonSizeStyleSet = {
  large: css`
    height: 6rem;
    line-height: 5.3rem;
    min-width: 16rem;
    padding: 0 2.4rem;
    font-size: 2.5rem;
    border-radius: 1rem;
  `,
  medium: css`
    height: 4rem;
    line-height: 3.1rem;
    min-width: 8rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
    border-radius: 0.5rem;
  `,
};

const getStyle: ButtonStyleGenerator<MainButtonProps> = state => options => {
  const [shadowH, shadowHHov] = shadowHeights[options.size];
  const shadowCol = shadowColors[options.color];

  return css`
    box-sizing: border-box;
    font-weight: bold;
    outline: 0;
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;

    box-shadow: 0px ${shadowH} 0px 0px ${shadowCol};
    &:hover {
      box-shadow: 0px ${shadowHHov} 0px 0px ${shadowCol};
      transform: translateY(calc(${shadowH} - ${shadowHHov}));
    }

    ${sizeStyles[options.size]}
    ${styles[options.color]?.[state]}

    ${options.loading &&
    `
      color: transparent;
    `}
  `;
};

const MainButtonWrapper = styled.button`
  border: none;

  ${getStyle('default')};

  &:hover {
    ${getStyle('hover')};
  }
  &:active {
    ${getStyle('active')};
  }
  &:disabled {
    ${getStyle('disabled')};
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export default MainButton;
