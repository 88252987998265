/**
 * React Higher-Order Components in TypeScript - James Ravenscroft - Medium
 * https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
 */
import * as React from 'react';
import styled from 'styled-components';

interface ContentProps {
  // loading: boolean;
}

const asContent = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) =>
  class AsContent extends React.Component<P & ContentProps> {
    render() {
      const { ...props } = this.props;
      return (
        <PageContentWrapper>
          <Component {...(props as P)} />
        </PageContentWrapper>
      );
    }
  };

const PageContentWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export default asContent;
