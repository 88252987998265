import KaihousuuImg from '../../../../static/svg/Kaihousuu.svg';
import Panel0Img from '../../../../static/svg/Panel_0_Stage1.svg';
import Panel1Img from '../../../../static/svg/Panel_1_Stage1.svg';
import Panel2Img from '../../../../static/svg/Panel_2_Stage1.svg';
import Panel3Img from '../../../../static/svg/Panel_3_Stage1.svg';
import Panel4Img from '../../../../static/svg/Panel_4_Stage1.svg';
import Panel5Img from '../../../../static/svg/Panel_5_Stage1.svg';
import Panel6Img from '../../../../static/svg/Panel_6_Stage1.svg';
import PanelPlusImg from '../../../../static/svg/Panel_+_Stage1.svg';
import PanelMinusImg from '../../../../static/svg/Panel_-_Stage1.svg';
import PanelEqualImg from '../../../../static/svg/Panel_=_Stage1.svg';
import Colors from '../../../../styles/colors';
import Keyframes from '../../../../styles/keyframes';
import * as Types from '../../../../types';
import _ErrorText from '../../../uiElements/ErrorText';
import _Button from '../../../uiElements/button/MainButton';
import DraggablePanel, {
  PanelState,
  Position,
  panelAreaOffset,
  panelSize,
} from './DraggablePanel';
import React from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import styled from 'styled-components';

export type PanelStates = { [v: string]: PanelState };

export const panelAreaStepSize = 100;

export const inputNames = ['top', 'bottom'] as const;
export type InputName = typeof inputNames[number];
export const inputWidth = 700;
export const inputHeight = 100;
export const inputTopPos: Record<InputName, number> = {
  top: 50,
  bottom: 300,
};
export const inputLeftPos = 50;

export const panels = ['0', '1', '2', '3', '4', '5', '6', 'p', 'm'] as const;
export type PanelKey = typeof panels[number];

export const panelImgs: Record<PanelKey, string> = {
  '0': Panel0Img,
  '1': Panel1Img,
  '2': Panel2Img,
  '3': Panel3Img,
  '4': Panel4Img,
  '5': Panel5Img,
  '6': Panel6Img,
  p: PanelPlusImg,
  m: PanelMinusImg,
};

export type PanelsInInput = Record<InputName, PanelKey[]>;

export const initialPanelsInInput = {
  top: [],
  bottom: [],
};

export const initialPanelStates: PanelStates = Object.fromEntries(
  panels.map((src, i) => [
    src,
    {
      rotation: 0,
      position: {
        x: panelAreaOffset.x + panelAreaStepSize * (i % 3),
        y: panelAreaOffset.y + panelAreaStepSize * Math.floor(i / 3),
      },
    },
  ])
);

interface DraggableAreaProps {
  errorMessage: string;
  resetErrors: () => void;
  setAnsStr: React.Dispatch<React.SetStateAction<string>>;
  onSend: () => void;
  achievedTargets: Types.Stage1Target[];
}

const DraggableArea: React.FC<DraggableAreaProps> = props => {
  const { errorMessage, resetErrors, setAnsStr, onSend, achievedTargets } =
    props;
  const [dragDelta, setDragDelta] = React.useState<Position>({ x: 0, y: 0 });
  const [panelStates, setPanelStates] =
    React.useState<PanelStates>(initialPanelStates);
  const [panelsInInput, setPanelsInInput] = React.useState<PanelsInInput>({
    ...initialPanelsInInput,
  });
  const [draggingPanel, setDraggingPanel] = React.useState<
    PanelKey | undefined
  >(undefined);

  // 入力欄に重なっているかどうか
  const findOverlappingInput = (
    key: string,
    dragging: boolean,
    panelStates: PanelStates
  ): [InputName | '', number] => {
    const state = panelStates[key];
    if (!state) return ['', -1];

    const pos = dragging
      ? {
          x: state.position.x + dragDelta.x,
          y: state.position.y + dragDelta.y,
        }
      : state.position;

    const centerPos: Position = {
      x: pos.x + panelSize / 2,
      y: pos.y + panelSize / 2,
    };

    for (const name of inputNames) {
      const targetInputTopPos = inputTopPos[name];
      const left = centerPos.x - inputLeftPos;
      const top = centerPos.y - targetInputTopPos;
      if (left >= 0 && left <= inputWidth && top >= 0 && top <= inputHeight) {
        return [name, Math.floor(left / panelAreaStepSize)];
      }
    }

    return ['', -1];
  };

  // ドラッグ中
  const onDrag =
    (key: PanelKey) => (e: DraggableEvent, data: DraggableData) => {
      const [overlappingInput, idx] = findOverlappingInput(
        key,
        true,
        panelStates
      );
      const newPanelsInInput = {
        top: [...panelsInInput.top],
        bottom: [...panelsInInput.bottom],
      };

      if (overlappingInput && !panelsInInput[overlappingInput].includes(key)) {
        newPanelsInInput[overlappingInput].push(key);
      }

      inputNames.forEach(name => {
        newPanelsInInput[name] = newPanelsInInput[name].filter(v => v !== key);
        if (name === overlappingInput) {
          newPanelsInInput[name].splice(idx, 0, key);
        }
      });
      resetErrors();
      setDraggingPanel(key);
      setPanelsInInput(newPanelsInInput);
      setDragDelta({
        x: dragDelta.x + data.deltaX,
        y: dragDelta.y + data.deltaY,
      });
    };

  // ドラッグ終わり
  const onStop = () => () => {
    setDraggingPanel(undefined);
    setDragDelta({ x: 0, y: 0 });
  };

  React.useEffect(() => {
    setPanelStates(panelStates => {
      const newPanelStates = { ...panelStates };

      // 位置リセット
      panels.forEach(panel => {
        if (panel === draggingPanel) return;
        newPanelStates[panel] = {
          ...newPanelStates[panel],
          rotation: 0,
          position: initialPanelStates[panel].position,
        };
      });

      // 入力欄に配置
      for (const name of inputNames) {
        const panels = panelsInInput[name];

        panels.forEach((key, i) => {
          if (key === draggingPanel) return;
          newPanelStates[key] = {
            ...newPanelStates[key],
            rotation: panelStates[key].rotation,
            position: {
              x: inputLeftPos + panelAreaStepSize * (i + 1) - panelSize,
              y: inputTopPos[name] + (inputHeight - panelSize) / 2,
            },
          };
        });
      }

      return newPanelStates;
    });
  }, [panelsInInput, draggingPanel]);

  // パネルが入力欄にあるか？
  const isInInput = (key: PanelKey, panelsInInput: PanelsInInput) => {
    for (const name of inputNames) {
      const panels = panelsInInput[name];
      if (panels.includes(key)) {
        return true;
      }
    }

    return false;
  };

  // まわす
  const rotatePanel = (key: string) => (diff: number) => {
    setPanelStates(panelStates => {
      const newPanelStates = { ...panelStates };

      newPanelStates[key] = {
        ...newPanelStates[key],
        rotation: newPanelStates[key].rotation + diff,
      };
      return newPanelStates;
    });
  };

  const inputName2AnsFormat = React.useCallback(
    (panelsInInput: PanelsInInput, panelStates: PanelStates) =>
      (name: InputName) => {
        const panels = panelsInInput[name];
        const mod = (n: number, m: number) => ((n % m) + m) % m;

        return panels
          .map(p => {
            const s = panelStates[p];
            return `${p}${mod(s.rotation, 8)}`;
          })
          .join(',');
      },
    []
  );

  // ansStr の更新
  React.useEffect(() => {
    const ansStr = inputNames
      .map(inputName2AnsFormat(panelsInInput, panelStates))
      .join('|');
    setAnsStr(ansStr);
  }, [panelsInInput, panelStates, inputName2AnsFormat, setAnsStr]);

  // リセット
  const onReset = () => {
    setPanelsInInput(initialPanelsInInput);
    resetErrors();
  };

  return (
    <>
      <DraggableAreaWrapper>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {panels.map(key => (
          <DraggablePanel
            key={key}
            src={panelImgs[key]}
            onDrag={onDrag(key)}
            onStop={onStop()}
            rotatePanel={rotatePanel(key)}
            panelState={panelStates[key]}
            isInInput={isInInput(key, panelsInInput)}
          />
        ))}

        <InputTop failed={errorMessage !== ''} />
        <Equal />
        <InputBottom failed={errorMessage !== ''} />
      </DraggableAreaWrapper>
      <Buttons>
        <Button color={'negative'} onClick={() => onReset()}>
          リセット
        </Button>
        <Button color={'positive'} onClick={() => onSend()}>
          送信
        </Button>
      </Buttons>
      <Kaihousuu />
      <KaihouNum>{achievedTargets.length}</KaihouNum>
    </>
  );
};

const DraggableAreaWrapper = styled.div`
  width: 114rem;
  height: 100%;
  position: relative;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
`;

const Equal = styled.img.attrs({
  src: PanelEqualImg,
  rotation: 0,
})`
  width: 4rem;
  height: 6rem;

  position: absolute;
  top: calc(
    ${(inputTopPos.top + inputHeight + inputTopPos.bottom) / 2}px - 3rem
  );
  left: calc(${inputLeftPos + inputWidth / 2}px - 2rem);
  z-index: 2;
`;

interface InputProps {
  failed?: boolean;
}

const Input = styled.div`
  width: ${inputWidth}px;
  height: ${inputHeight}px;
  background-color: ${Colors.white};
  border: ${Colors.gray6} 2px solid;
  z-index: 0;
  position: absolute;
  left: ${inputLeftPos}px;

  ${(p: InputProps) =>
    p.failed &&
    `
    border-color: ${Colors.error};
    background-color: ${Colors.highlightError};
    ${Keyframes.vibrate};
  `}
`;

const InputTop = styled(Input)`
  top: ${inputTopPos.top}px;
`;

const InputBottom = styled(Input)`
  top: ${inputTopPos.bottom}px;
`;

const ErrorText = styled(_ErrorText)`
  position: absolute;
  top: calc(${inputTopPos.top}px - 3rem);
  left: ${inputLeftPos}px;
`;

const Button = styled(_Button).attrs({
  variant: 'primary',
  size: 'large',
})`
  max-width: 47%;
`;

const Buttons = styled.div`
  margin-top: 4rem;
  width: 100%;
  height: 6rem;
  position: absolute;
  top: 75%;
  display: flex;
  justify-content: center;

  button {
    margin-right: 6rem;
  }
  button:last-child {
    margin-right: 0;
  }
`;

const Kaihousuu = styled.img.attrs({
  src: KaihousuuImg,
})`
  position: absolute;
  bottom: 14rem;
  right: 17rem;
  height: 3.4rem;
`;
const KaihouNum = styled.div`
  position: absolute;
  height: 7rem;
  width: 14rem;
  bottom: 13.6rem;
  right: 3rem;
  font-size: 7rem;
  line-height: 7rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.2rem;

  color: ${Colors.gray8};
`;

export default DraggableArea;
