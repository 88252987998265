import { Stage1Target } from '../../types';

export interface Stage1Answer {
  targetId: number;
  answer: string[];
  target: number[];
}
export interface Stage1CollateValue {
  target: number[];
  value: number;
  code: string;
}

export const target: Stage1Target[] = [
  {
    name: '足し算',
    targetId: 1,
    score: 1,
  },
  {
    name: '引き算',
    targetId: 2,
    score: 1,
  },
  {
    name: '1桁の数字',
    targetId: 3,
    score: 1,
  },
  {
    name: '2桁の数字',
    targetId: 4,
    score: 1,
  },
  {
    name: 'かけ算',
    targetId: 5,
    score: 2,
  },
  {
    name: '3桁の数字',
    targetId: 6,
    score: 2,
  },
  {
    name: '６<->９',
    targetId: 7,
    score: 2,
  },
  {
    name: '－<->１',
    targetId: 8,
    score: 2,
  },
  {
    name: '割り算',
    targetId: 9,
    score: 3,
  },
  {
    name: '4桁の数字',
    targetId: 10,
    score: 3,
  },
  {
    name: '両辺に数式',
    targetId: 11,
    score: 3,
  },
  {
    name: '負の数',
    targetId: 12,
    score: 3,
  },
  {
    name: 'アルファベット',
    targetId: 13,
    score: 3,
  },
  {
    name: 'カタカナ',
    targetId: 14,
    score: 3,
  },
  {
    name: 'ひらがな',
    targetId: 15,
    score: 3,
  },
  {
    name: '漢字',
    targetId: 16,
    score: 3,
  },
  {
    name: 'ローマ数字',
    targetId: 17,
    score: 3,
  },
  {
    name: 'ローマ字',
    targetId: 18,
    score: 3,
  },
  {
    name: '英単語',
    targetId: 19,
    score: 3,
  },
  {
    name: '累乗',
    targetId: 20,
    score: 4,
  },
  {
    name: 'バツ印',
    targetId: 21,
    score: 4,
  },
  {
    name: '線',
    targetId: 22,
    score: 4,
  },
  {
    name: '三角関数',
    targetId: 23,
    score: 4,
  },
  {
    name: '2進数',
    targetId: 24,
    score: 4,
  },
  {
    name: '16進数',
    targetId: 25,
    score: 4,
  },
  {
    name: '時間',
    targetId: 26,
    score: 4,
  },
  {
    name: '方角',
    targetId: 27,
    score: 4,
  },
  {
    name: 'トランプ',
    targetId: 28,
    score: 4,
  },
  {
    name: '絶対値',
    targetId: 29,
    score: 4,
  },
  {
    name: 'ハングル',
    targetId: 30,
    score: 3,
  },
  {
    name: '地図記号',
    targetId: 31,
    score: 4,
  },
  {
    name: '8進数',
    targetId: 32,
    score: 4,
  },
  {
    name: '指数表記',
    targetId: 33,
    score: 4,
  },
  {
    name: 'SI接頭辞',
    targetId: 34,
    score: 4,
  },
  {
    name: '音階',
    targetId: 35,
    score: 4,
  },
  {
    name: '元素記号',
    targetId: 36,
    score: 4,
  },
  {
    name: 'アルファベット順',
    targetId: 37,
    score: 3,
  },
];

export const answer: Stage1Answer[] = [
  {
    targetId: 1,
    answer: ['50,10,22,64,00|12'],
    target: [7, 13, 23, 16, 4],
  },
  {
    targetId: 2,
    answer: ['12|12'],
    target: [16],
  },
  {
    targetId: 3,
    answer: ['12|00,22,34'],
    target: [16, 13, 19],
  },
  {
    targetId: 4,
    answer: ['00,10|22,10'],
    target: [13, 18, 30],
  },
  {
    targetId: 5,
    answer: ['p0,32,00|22,10'],
    target: [19, 13, 18],
  },
  {
    targetId: 6,
    answer: ['10,10|p0,32,00'],
    target: [19, 13, 17],
  },
  {
    targetId: 7,
    answer: ['30,11,20,46,00|,10,p1'],
    target: [17, 13, 18, 14, 3, 20],
  },
  {
    targetId: 8,
    answer: ['46|p0,12'],
    target: [13, 16, 28],
  },
  {
    targetId: 9,
    answer: ['46|p1,12'],
    target: [13, 17, 28],
  },
  {
    targetId: 10,
    answer: ['10,00|20'],
    target: [24, 3, 4],
  },
  {
    targetId: 11,
    answer: ['10,10|30'],
    target: [24, 3, 4],
  },
  {
    targetId: 12,
    answer: ['10,00,10|50'],
    target: [24, 3, 6],
  },
  {
    targetId: 13,
    answer: ['10,10,00|60'],
    target: [24, 3, 6],
  },
  {
    targetId: 14,
    answer: ['10,44|60,00,36'],
    target: [26, 13, 3, 4],
  },
  {
    targetId: 15,
    answer: ['10,36|60,00,50'],
    target: [26, 13, 3, 4],
  },
  {
    targetId: 16,
    answer: ['10,44|60,00,36,10,22'],
    target: [13, 26, 3, 4],
  },
  {
    targetId: 17,
    answer: ['22,00,50,34|11,13,p0'],
    target: [19, 13, 14],
  },
  {
    targetId: 18,
    answer: ['20|10'],
    target: [13, 16, 18],
  },
  {
    targetId: 19,
    answer: ['22,00|p1'],
    target: [13, 21, 19],
  },
  {
    targetId: 20,
    answer: ['22,64|p1'],
    target: [13, 21, 19],
  },
  {
    targetId: 21,
    answer: ['22,00|10,10,34'],
    target: [13, 18, 19],
  },
  {
    targetId: 22,
    answer: ['62|11'],
    target: [14, 15],
  },
  {
    targetId: 23,
    answer: ['22,00|62'],
    target: [13, 15, 18],
  },
  {
    targetId: 24,
    answer: ['22,00|11'],
    target: [13, 14, 18],
  },
  {
    targetId: 25,
    answer: ['22,00|62,12'],
    target: [13, 15, 19],
  },
  {
    targetId: 26,
    answer: ['22,00|11,12'],
    target: [13, 14, 19],
  },
  {
    targetId: 27,
    answer: ['10|50,34,22'],
    target: [13, 18, 22],
  },
  {
    targetId: 28,
    answer: ['12|50,34,22'],
    target: [13, 18, 22],
  },
  {
    targetId: 29,
    answer: ['32|22,10,50,10'],
    target: [13, 18, 27],
  },
  {
    targetId: 30,
    answer: ['32|22,10,50,44,10'],
    target: [13, 18, 27],
  },
  {
    targetId: 31,
    answer: ['10,10,00|p1'],
    target: [6, 31],
  },
  {
    targetId: 32,
    answer: ['34|10,40'],
    target: [13, 25, 4, 3],
  },
  {
    targetId: 33,
    answer: ['20,34|40,60'],
    target: [13, 25, 4],
  },
  {
    targetId: 34,
    answer: ['50,34|64,40'],
    target: [13, 25, 4],
  },
  {
    targetId: 35,
    answer: ['60,34|10,10,00'],
    target: [13, 25, 6, 4],
  },
  {
    targetId: 36,
    answer: ['20,40,34|50,64,00'],
    target: [13, 25, 6],
  },
  {
    targetId: 37,
    answer: ['50,00,34|10,20,64,40'],
    target: [13, 25, 10, 6],
  },
  {
    targetId: 38,
    answer: ['10,34,20|44'],
    target: [13, 34, 33, 3],
  },
  {
    targetId: 39,
    answer: ['50,00|64'],
    target: [13, 18, 35],
  },
  {
    targetId: 40,
    answer: ['34|50'],
    target: [13, 3, 37],
  },
  {
    targetId: 41,
    answer: ['34|50,11,20,60'],
    target: [13, 3, 37, 4],
  },
  {
    targetId: 42,
    answer: ['10|64'],
    target: [13, 3, 37],
  },
  {
    targetId: 43,
    answer: ['46|10,00'],
    target: [13, 4, 37],
  },
  {
    targetId: 44,
    answer: ['46|10,00,11,20,60'],
    target: [13, 4, 37],
  },
  {
    targetId: 45,
    answer: ['10|10,20'],
    target: [13, 4, 37],
  },
  {
    targetId: 46,
    answer: ['22|10,40'],
    target: [13, 4, 37],
  },
  {
    targetId: 47,
    answer: ['00|10,50'],
    target: [13, 4, 37],
  },
  {
    targetId: 48,
    answer: ['00|10,50,11,20,60'],
    target: [13, 4, 37],
  },
  {
    targetId: 49,
    answer: ['50|10,64'],
    target: [13, 4, 37],
  },
  {
    targetId: 50,
    answer: ['p1|20,40'],
    target: [13, 4, 37],
  },
  {
    targetId: 51,
    answer: ['36,64|10,20'],
    target: [13, 36, 4],
  },
  {
    targetId: 52,
    answer: ['50|10,60'],
    target: [13, 36, 4],
  },
  {
    targetId: 53,
    answer: ['22,60|40,10'],
    target: [13, 36, 4],
  },
  {
    targetId: 54,
    answer: ['36,00|40,20'],
    target: [13, 36, 4],
  },
  {
    targetId: 55,
    answer: ['10|50,30'],
    target: [13, 36, 4],
  },
  {
    targetId: 56,
    answer: ['50,36|60,20'],
    target: [13, 36, 4],
  },
  {
    targetId: 57,
    answer: ['22,44|10,10,30'],
    target: [13, 36, 6],
  },
];

export const collateValues = [
  {
    collateValueId: 1,
    code: '50,10,22,64,00',
    value: 1,
    targets: [7, 13, 23, 4],
  },
  {
    collateValueId: 2,
    code: '12',
    value: 1,
    targets: [16],
  },
  {
    collateValueId: 3,
    code: '00,22,34',
    value: 1,
    targets: [19, 13],
  },
  {
    collateValueId: 4,
    code: '00,10',
    value: 2,
    targets: [30],
  },
  {
    collateValueId: 5,
    code: '10,10',
    value: 2,
    targets: [17],
  },
  {
    collateValueId: 6,
    code: 'p0,32,00',
    value: 2,
    targets: [19, 13],
  },
  {
    collateValueId: 7,
    code: '22,10',
    value: 2,
    targets: [13, 18],
  },
  {
    collateValueId: 8,
    code: '50,10',
    value: 4,
    targets: [13, 18],
  },
  {
    collateValueId: 9,
    code: '64,00',
    value: 5,
    targets: [13, 18],
  },
  {
    collateValueId: 10,
    code: '50,10,p1',
    value: 6,
    targets: [19, 13],
  },
  {
    collateValueId: 11,
    code: '11,13',
    value: 8,
    targets: [16],
  },
  {
    collateValueId: 12,
    code: '10,p1',
    value: 9,
    targets: [17],
  },
  {
    collateValueId: 13,
    code: '30,11,20,46,00',
    value: 9,
    targets: [13, 18, 20, 3, 14],
  },
  {
    collateValueId: 14,
    code: 'p1',
    value: 10,
    targets: [17],
  },
  {
    collateValueId: 15,
    code: 'p0',
    value: 10,
    targets: [16],
  },
  {
    collateValueId: 16,
    code: 'p0,34,22',
    value: 10,
    targets: [13, 19],
  },
  {
    collateValueId: 17,
    code: '46',
    value: 11,
    targets: [13, 28],
  },
  {
    collateValueId: 18,
    code: 'p0,12',
    value: 11,
    targets: [16],
  },
  {
    collateValueId: 19,
    code: 'p1,10',
    value: 11,
    targets: [17],
  },
];
