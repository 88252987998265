import { submitAnswer5 } from '../../../../api/extensions/game/game5';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useSetLoadingState } from '../../../../redux/actions/appActions';
import { timeActions } from '../../../../redux/actions/timeActions';
import { useErrorState } from '../../../../redux/selectors/appSelectors';
import { useStage5GroupInfo } from '../../../../redux/selectors/gameSelectors';
import {
  useRemainingTime,
  useTimerDuration,
  useTimerStartTime,
} from '../../../../redux/selectors/timeSelectors';
import { GroupState } from '../../../../types';
import FinishPage from '../../common/FinishPage';
import GameAnswerer from './GameAnswerer';
import GameBeforeStart from './GameBeforeStart';
import GamePolling from './GamePolling';
import GamePresenter from './GamePresenter';
import { GroupMemberItem } from './GroupInfo';
import { useStage5Problem } from './data/data';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface GameManagerProps {
  playerId: number;
  initialProblemId: number;
}

const PROBLEM_DURATION = 20;

const useExtractedGroupDocData = (playerId: number, problemId: number) => {
  const startMillisTimeOnServer = React.useMemo(() => {
    return Date.now();
  }, []);

  const isPresenter = useMemo(
    () => ((problemId - 1) % 3) + 1 === playerId,
    [playerId, problemId]
  );
  const problem = useStage5Problem(problemId);
  return useMemo(
    () => ({
      isPresenter,
      startMillisTimeOnServer,
      problemId,
      problem,
    }),
    [isPresenter, startMillisTimeOnServer, problemId, problem]
  );
};

const useSubmitAnswerCallback = (problemId: number | undefined) => {
  return React.useCallback(
    (answer: number | null) => {
      if (problemId === undefined) {
        return;
      }
      submitAnswer5({
        answer: '' + (answer ?? '-'),
        problemId,
      });
    },
    [problemId]
  );
};

const useStage5TimerEffect = (
  startMillisTimeOnServer: number | undefined,
  groupState: GroupState | undefined
) => {
  const dispatch = useDispatch();
  const timerStartTime = useTimerStartTime();
  const timerDuration = useTimerDuration();
  useEffect(() => {
    if (startMillisTimeOnServer === undefined) return;
    const startTimeOnServer = startMillisTimeOnServer / 1000;
    if (
      timerDuration === PROBLEM_DURATION &&
      timerStartTime === startTimeOnServer
    ) {
      return;
    }
    dispatch(
      timeActions.setTimer({
        startTimeOnServer,
        duration: PROBLEM_DURATION,
      })
    );
  }, [
    dispatch,
    timerDuration,
    timerStartTime,
    startMillisTimeOnServer,
    groupState,
  ]);
};

const GameManager: React.FC<GameManagerProps> = ({
  playerId,
  initialProblemId,
}) => {
  const groupInfo = useStage5GroupInfo();
  const navigate = useNavigate();
  const [problemId, setProblemId] = React.useState(initialProblemId);
  const [groupState, setGroupState] = React.useState<GroupState>(
    GroupState.Preparetostart
  );
  const [skipped, setSkipped] = React.useState(false);
  const [eventId] = useLocalStorage<string>(':eventId:', '');

  const dispatch = useDispatch();
  const errorOverlayState = useErrorState();
  const setLoadingState = useSetLoadingState();

  const remainingTime = useRemainingTime();

  const { isPresenter, startMillisTimeOnServer, problem } =
    useExtractedGroupDocData(playerId, problemId);
  const [inactiveMemberDatas, setInactiveMemberDatas] = React.useState<
    GroupMemberItem[]
  >([]);

  useStage5TimerEffect(startMillisTimeOnServer, groupState);
  const onStageStart = () => {
    dispatch(timeActions.clearTimer());
    setGroupState(GroupState.Ongoing);
  };

  const onSubmitAnswer = useSubmitAnswerCallback(problemId);

  // Timer の設定
  // useStage5TimerEffect(startMillisTimeOnServer, groupState);

  // Onging
  React.useEffect(() => {
    if (groupState !== GroupState.Ongoing) return;
    if (remainingTime === null) return;
    if (!skipped && remainingTime > 0) return;
    if (isPresenter) {
      setLoadingState({
        visible: true,
        text: '解答を締め切っています...',
      });
    } else {
      setLoadingState({
        visible: true,
        text: '判定中...',
      });
    }
    setGroupState(GroupState.Showinganswer);
    setTimeout(
      () => {
        setLoadingState({
          visible: false,
        });
        if (problemId === 6 || problemId === 3) {
          setGroupState(GroupState.Finished);
        } else {
          setGroupState(GroupState.Preparetostart);
          setProblemId(prev => prev + 1);
        }
        setSkipped(false);
      },
      skipped ? 0 : 5 * 1000
    );
  }, [
    groupState,
    isPresenter,
    problemId,
    remainingTime,
    setLoadingState,
    skipped,
  ]);
  // Showinganswer
  React.useEffect(() => {
    if (groupState !== GroupState.Showinganswer) return;
    if (isPresenter) {
      setLoadingState({
        visible: true,
        text: '判定中...',
      });
    } else {
      setLoadingState({
        visible: false,
      });
    }
  }, [groupState, isPresenter, setLoadingState]);

  // Unmount
  React.useEffect(() => {
    return () => {
      setLoadingState({
        visible: false,
      });
    };
  }, [setLoadingState]);

  switch (groupState) {
    case GroupState.Preparetostart:
      return (
        <GameBeforeStart
          isPresenter={isPresenter}
          playerId={playerId}
          problemId={problemId}
          onStart={onStageStart}
        />
      );
    case GroupState.Polling:
      return (
        <GamePolling searchId={0} inactiveMemberDatas={inactiveMemberDatas} />
      );
    case GroupState.Ongoing:
    case GroupState.Showinganswer:
      return isPresenter ? (
        <GamePresenter
          problem={problem}
          searchId={0}
          onSkip={() => {
            setSkipped(true);
          }}
        />
      ) : (
        <GameAnswerer
          problem={problem}
          searchId={0}
          showingAnswer={groupState === GroupState.Showinganswer}
          onChangeAnswer={onSubmitAnswer}
          onSkip={() => {
            setSkipped(true);
          }}
        />
      );
    default:
      return (
        <FinishPage
          onExit={() => {
            navigate(`/events/${eventId}/stages/`);
          }}
        />
      );
  }
};

export default GameManager;
