import Q1_0 from '../../../../../static/png/sampleComm/1st/Q1_0.png';
import Q1_1 from '../../../../../static/png/sampleComm/1st/Q1_1.png';
import Q1_2 from '../../../../../static/png/sampleComm/1st/Q1_2.png';
import Q1_3 from '../../../../../static/png/sampleComm/1st/Q1_3.png';
import Q1_4 from '../../../../../static/png/sampleComm/1st/Q1_4.png';
import Q2_0 from '../../../../../static/png/sampleComm/1st/Q2_0.png';
import Q2_1 from '../../../../../static/png/sampleComm/1st/Q2_1.png';
import Q2_2 from '../../../../../static/png/sampleComm/1st/Q2_2.png';
import Q2_3 from '../../../../../static/png/sampleComm/1st/Q2_3.png';
import Q2_4 from '../../../../../static/png/sampleComm/1st/Q2_4.png';
import Q3_0 from '../../../../../static/png/sampleComm/1st/Q3_0.jpg';
import Q3_1 from '../../../../../static/png/sampleComm/1st/Q3_1.jpg';
import Q3_2 from '../../../../../static/png/sampleComm/1st/Q3_2.jpg';
import Q3_3 from '../../../../../static/png/sampleComm/1st/Q3_3.jpg';
import Q3_4 from '../../../../../static/png/sampleComm/1st/Q3_4.jpg';
import Q4_0 from '../../../../../static/png/sampleComm/2nd/Q4_0.jpg';
import Q4_1 from '../../../../../static/png/sampleComm/2nd/Q4_1.jpg';
import Q4_2 from '../../../../../static/png/sampleComm/2nd/Q4_2.jpg';
import Q4_3 from '../../../../../static/png/sampleComm/2nd/Q4_3.jpg';
import Q4_4 from '../../../../../static/png/sampleComm/2nd/Q4_4.jpg';
import Q5_0 from '../../../../../static/png/sampleComm/2nd/Q5_0.png';
import Q5_1 from '../../../../../static/png/sampleComm/2nd/Q5_1.png';
import Q5_2 from '../../../../../static/png/sampleComm/2nd/Q5_2.png';
import Q5_3 from '../../../../../static/png/sampleComm/2nd/Q5_3.png';
import Q5_4 from '../../../../../static/png/sampleComm/2nd/Q5_4.png';
import Q6_0 from '../../../../../static/png/sampleComm/2nd/Q6_0.png';
import Q6_1 from '../../../../../static/png/sampleComm/2nd/Q6_1.png';
import Q6_2 from '../../../../../static/png/sampleComm/2nd/Q6_2.png';
import Q6_3 from '../../../../../static/png/sampleComm/2nd/Q6_3.png';
import Q6_4 from '../../../../../static/png/sampleComm/2nd/Q6_4.png';
import { useMemo } from 'react';

export const stage5problems: [string, string, string, string, string][] = [
  [Q1_0, Q1_1, Q1_2, Q1_3, Q1_4],
  [Q2_0, Q2_1, Q2_2, Q2_3, Q2_4],
  [Q3_0, Q3_1, Q3_2, Q3_3, Q3_4],
  [Q4_0, Q4_1, Q4_2, Q4_3, Q4_4],
  [Q5_0, Q5_1, Q5_2, Q5_3, Q5_4],
  [Q6_0, Q6_1, Q6_2, Q6_3, Q6_4],
];

export const stage5Answers = [3, 4, 1, 1, 4, 3];

interface PresenterText {
  main: string;
  sub?: string;
}
interface Stage5TextItem {
  presenter: PresenterText;
  answerer: string;
}

export const stage5Texts: Stage5TextItem[] = [
  {
    presenter: { main: '風景を説明してください。' },
    answerer: '相手が見ている景色は？',
  }, // 4,
  {
    presenter: { main: 'どんな人か説明してください。' },
    answerer: '相手が見ている人は？',
  }, // 15
  {
    presenter: { main: 'グラフを伝えてください。' },
    answerer: '相手が説明しているグラフは？',
  }, // 5
  {
    presenter: { main: '道順を説明してください。' },
    answerer: '相手が説明している道順は？',
  }, // 16
  {
    presenter: { main: '文字を伝えてください。' },
    answerer: '相手が説明している文字は？',
  }, // 18
  {
    presenter: { main: '写真を伝えてください。' },
    answerer: '相手が説明しているのは？',
  }, // 18
];

export interface Stage5Problem {
  index: number;
  text: Stage5TextItem;
  imageSrc: [string, string, string, string, string];
  answer: number;
}

export const useStage5Problem = (problemId: number): Stage5Problem => {
  const problemIndex = problemId - 1;
  return useMemo<Stage5Problem>(
    () => ({
      index: problemIndex,
      imageSrc: stage5problems[problemIndex],
      text: stage5Texts[problemIndex],
      answer: stage5Answers[problemIndex],
    }),
    [problemIndex]
  );
};
