import useLocalStorage from '../../../../lib/useLocalStorage';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import StageManager from '../../stageProviders/StageManager';
import GameManager from './GameManager';
import PlayerSelector from './PlayerSelector';
import * as React from 'react';
import { useState } from 'react';

interface Stage5Props {
  problemId: number;
}
const Stage5: React.FC<Stage5Props> = ({ problemId }) => {
  const [showingGame, setShowingGame] = useState(false);

  const [playerId, setPlayerId] = useState(0);
  const groupStart = React.useCallback(
    playerId => {
      setPlayerId(playerId);
      setShowingGame(true);
    },
    [setShowingGame]
  );

  return (
    <StageManager stageId={5} noCountDown noRecordStart>
      {showingGame ? (
        <GameManager playerId={playerId} initialProblemId={problemId} />
      ) : (
        <PlayerSelector onStart={groupStart} />
      )}
    </StageManager>
  );
};

export default Stage5;
