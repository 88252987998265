import { db } from '../index';
import { timeActions } from '../redux/actions/timeActions';
import {
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type SnapshotData =
  | {
      timestamp: Timestamp;
    }
  | undefined;

export const useServerTimeOffsetEffect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const ref = doc(db, 'version', '1', 'dummy_for_time_get', 'time');
    setDoc(ref, {
      timestamp: serverTimestamp(),
    }).then(() => {
      onSnapshot(ref, (snapshot: any) => {
        const timestamp = (snapshot.data() as SnapshotData)?.timestamp;
        const offset =
          timestamp != null
            ? (timestamp.toMillis() - Date.now()) / 1000
            : undefined;
        dispatch(timeActions.setServerTimeOffset(offset));
      });
    });
  }, [dispatch]);
};
