import asContent from '../../../hoc/asContent';
import useLocalStorage from '../../../lib/useLocalStorage';
import { timeActions } from '../../../redux/actions/timeActions';
import { useStagesFinished } from '../../../redux/selectors/gameSelectors';
import UserNameView from '../../mypage/UserNameView';
import CommonBG from '../../uiElements/CommonBG';
import FinishPage from '../common/FinishPage';
import Comm1Rule from '../stagePages/comm1/Comm1Rule';
import Stage1 from '../stagePages/stage1/Stage1';
import Stage5 from '../stagePages/stage5/Stage5';
import StageSelector from '../stagesUtil/StageSelector';
import Finished from './Finished';
import IntervalExplanation from './IntervalExplanation';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface StagesProps {}

const StagesRouter: React.FC<StagesProps> = props => {
  const navigate = useNavigate();
  const match = useMatch('/events/:eventId/stages');
  const [eventId, setEventId] = useLocalStorage<string>(':eventId:', '');
  useEffect(() => {
    if (match?.params.eventId) {
      if (match.params.eventId !== 'sample') {
        navigate('/');
      }
      setEventId(match.params.eventId);
    }
  }, [match]);

  return (
    <Routes>
      <Route path={`/1`} element={<Stage1 />} />
      <Route path={`/2`} element={<Stage5 problemId={1} />} />
      <Route path={`/3`} element={<IntervalExplanation />} />
      <Route path={`/4`} element={<Stage1 />} />
      <Route path={`/5`} element={<Stage5 problemId={4} />} />
      <Route path={`/*`} element={<Stages {...props} />} />
      <Route path={`*`} element={<Stages {...props} />} />
    </Routes>
  );
};

const Stages: React.FC<StagesProps> = () => {
  const stagesFinished = useStagesFinished();
  const allFinished = React.useMemo(
    () => !Object.values(stagesFinished).includes(false),
    [stagesFinished]
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(timeActions.clearTimer());
  });

  return (
    <CommonBG
      withHeader
      withFooter
      headerOptions={{ buttonType: 'logout', logoType: 'CT' }}
    >
      <StagesWrapper>
        <UserNameWrapper>
          <UserNameView userName={'デモユーザー'} />
        </UserNameWrapper>
        <StageSelector />
      </StagesWrapper>
    </CommonBG>
  );
};

const StagesWrapper = styled.div`
  padding-top: 5rem;
  text-align: center;
`;

const UserNameWrapper = styled.div`
  width: 55rem;
  margin: 4rem auto 0;
`;

export default asContent(StagesRouter);
