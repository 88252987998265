import Colors, { colorWithAlpha } from '../../styles/colors';
import styled from 'styled-components';

interface TextWindowProps {
  dark?: boolean;
  bracket?: boolean;
}

const TextWindow = styled.div`
  background-color: ${(p: TextWindowProps) =>
    p.dark ? colorWithAlpha('gray8', 0.9) : colorWithAlpha('gray4', 0.25)};
  color: ${(p: TextWindowProps) => (p.dark ? Colors.white : 'inherit')};
  min-height: 5rem;
  min-width: 54rem;
  padding: 1.8rem 6rem;
  line-height: 5rem;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.06rem;
  text-align: center;
  white-space: pre-line;
  position: relative;

  ${(p: TextWindowProps) =>
    p.bracket == true
      ? `
    &:before,
    &:after {
      content: '';
      display: block;
      border: 3px solid ${p.dark ? Colors.white : Colors.gray8};
      width: 10px;
      height: calc(100% - 6px);
      position: absolute;
      top: 0;
    }
    &:before {
      border-right: none;
      left: 0;
    }
    &:after {
      border-left: none;
      right: 0;
    }  
  `
      : ''};
`;

export default TextWindow;
