import { colorWithAlpha } from '../../styles/colors';
import React from 'react';
import styled from 'styled-components';

const Icons = [
  <svg id='Stage-1' xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -2 92 84'>
    <path
      id='多角形_15'
      data-name='多角形 15'
      className='cls-1'
      d='M36.62,4.2A10,10,0,0,1,48.3,4.2L78.98,26.281a10,10,0,0,1,3.661,11.234l-11.679,35.6A10,10,0,0,1,61.46,80h-38a10,10,0,0,1-9.5-6.883L2.282,37.514A10,10,0,0,1,5.943,26.281Z'
    />
    <path
      id='パス_1168'
      data-name='パス 1168'
      className='cls-2'
      d='M17.984.352V-19.936A5.426,5.426,0,0,0,12.16-25.7H1.344v9.408H7.232a.626.626,0,0,1,.7.7V.352Z'
      transform='translate(31.531 59)'
    />
  </svg>,
  <svg id='Stage-2' xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -2 92 84'>
    <path
      id='多角形_15'
      data-name='多角形 15'
      className='cls-1'
      d='M36.62,4.2A10,10,0,0,1,48.3,4.2L78.98,26.281a10,10,0,0,1,3.661,11.234l-11.679,35.6A10,10,0,0,1,61.46,80h-38a10,10,0,0,1-9.5-6.883L2.282,37.514A10,10,0,0,1,5.943,26.281Z'
    />
    <path
      id='パス_1169'
      data-name='パス 1169'
      className='cls-2'
      d='M27.424-16.608v-.576c0-5.376-2.784-8.512-9.76-8.512H1.248v8.256H16.32c.768,0,1.184.256,1.184.8v.128c0,.512-.32.864-1.184.864H1.248v16h25.5V-7.936H11.072V-9.152h6.592C25.344-9.152,27.424-12.672,27.424-16.608Z'
      transform='translate(27.962 59)'
    />
  </svg>,
  <svg id='Stage-3' xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -2 92 84'>
    <path
      id='多角形_15'
      data-name='多角形 15'
      className='cls-1'
      d='M36.62,4.2A10,10,0,0,1,48.3,4.2L78.98,26.281a10,10,0,0,1,3.661,11.234l-11.679,35.6A10,10,0,0,1,61.46,80h-38a10,10,0,0,1-9.5-6.883L2.282,37.514A10,10,0,0,1,5.943,26.281Z'
    />
    <path
      id='パス_1170'
      data-name='パス 1170'
      className='cls-2'
      d='M26.784-6.816V-8.608a5.4,5.4,0,0,0-2.016-4.32,5.529,5.529,0,0,0,1.5-3.84V-18.88c0-4.384-2.592-6.816-8.32-6.816H1.216v8.256H15.808a.711.711,0,0,1,.8.8v.224a.7.7,0,0,1-.768.736H1.088v5.888H16.032c.64,0,.864.384.864.736V-8.7c0,.512-.192.768-.864.768H.992V.384H18.08C23.488.384,26.784-.512,26.784-6.816Z'
      transform='translate(28.461 59)'
    />
  </svg>,
  <svg id='Stage-4' xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -2 92 84'>
    <path
      id='多角形_15'
      data-name='多角形 15'
      className='cls-1'
      d='M36.62,4.2A10,10,0,0,1,48.3,4.2L78.98,26.281a10,10,0,0,1,3.661,11.234l-11.679,35.6A10,10,0,0,1,61.46,80h-38a10,10,0,0,1-9.5-6.883L2.282,37.514A10,10,0,0,1,5.943,26.281Z'
    />
    <path
      id='パス_1171'
      data-name='パス 1171'
      className='cls-2'
      d='M27.36-3.2v-8.9H24.384V-25.7H14.816v13.6H12c-1.024,0-1.28-.352-1.28-1.28V-25.7H1.184v13.728C1.184-5.792,3.04-3.2,10.048-3.2h4.768V.384h9.568V-3.2Z'
      transform='translate(28.461 59)'
    />
  </svg>,
  <svg id='Stage-5' xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -2 92 84'>
    <path
      id='多角形_15'
      data-name='多角形 15'
      className='cls-1'
      d='M36.62,4.2A10,10,0,0,1,48.3,4.2L78.98,26.281a10,10,0,0,1,3.661,11.234l-11.679,35.6A10,10,0,0,1,61.46,80h-38a10,10,0,0,1-9.5-6.883L2.282,37.514A10,10,0,0,1,5.943,26.281Z'
    />
    <path
      id='パス_1172'
      data-name='パス 1172'
      className='cls-2'
      d='M26.944-7.52v-1.5c0-4.8-1.952-7.616-8.768-7.616h-7.04c-.352,0-.576-.128-.576-.448v-.8h15.3V-25.7H1.152v12.384c0,2.368.9,3.552,3.328,3.552H16.288c.608,0,.864.256.864.736v.192c0,.48-.192.736-.864.736H1.152V.384H18.176C24.576.384,26.944-2.08,26.944-7.52Z'
      transform='translate(28.461 59)'
    />
  </svg>,
];

type StageNumberProps = {
  stageNum: 1 | 2 | 3 | 4 | 5;
  variant?: 'black';
};
const StageNumber: React.FC<StageNumberProps> = ({ stageNum, variant }) => {
  return <Wrapper variant={variant}>{Icons[stageNum - 1]}</Wrapper>;
};

type WrapperProps = {
  variant?: 'black';
};
const Wrapper = styled.div`
  height: 100%;
  svg {
    height: 100%;
  }
  .cls-1 {
    fill: ${(p: WrapperProps) =>
      p.variant === 'black'
        ? colorWithAlpha('gray8', 1.0)
        : colorWithAlpha('primary', 1.0)};
  }

  .cls-2 {
    fill: #fff;
  }
`;

export default StageNumber;
