import Information from '../mypage/Information';
import EventResult from './eventResultPage/EventResultPage';
import Stages from './stageProviders/Stages';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const match = useMatch('/events/:eventId');
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path='/:eventId/stages/*' element={<Stages />} />
    </Routes>
  );
};

export default EventsRouter;
