import PlayerImg from '../../static/svg/MyPage_Player.svg';
import * as React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import styled from 'styled-components';

interface UserNameViewProps {
  userName: string;
}

const UserNameView: React.FC<UserNameViewProps> = props => {
  return (
    <UserNameViewWrapper>
      <SVG src={PlayerImg} />
      <h1>{props.userName}</h1>
    </UserNameViewWrapper>
  );
};

const UserNameViewWrapper = styled.div`
  text-align: center;

  h1 {
    text-align: center;
    font-size: 4.5rem;
    font-weight: bold;
  }

  svg {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem;
  }
`;

export default UserNameView;
