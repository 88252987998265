import BGImg from '../../static/svg/footer/footer_bg.svg';
import LogoImg from '../../static/svg/footer/riddler_logo.svg';
import Colors from '../../styles/colors';
import * as React from 'react';
import styled from 'styled-components';

interface FooterProps {}
const Footer: React.FC<FooterProps> = () => {
  return (
    <FooterWrapper>
      <Line></Line>
      <Line></Line>
      <Logo src={LogoImg} />
      <Text>©2021 RIDDLER, Inc. All Rights Reserved.</Text>
    </FooterWrapper>
  );
};

const FOOTER_HEIGHT = '4rem';
const LOGO_HEIGHT = '2rem';
const FooterWrapper = styled.div`
  width: 100%;
  height: ${FOOTER_HEIGHT};
  margin: 0;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: right;
  display: flex;
  align-items: center;
  background: ${Colors.gray2};
`;

const Logo = styled.img`
  height: ${LOGO_HEIGHT};
`;

const Text = styled.span`
  color: ${Colors.gray8};
  font-size: 1.2rem;
  margin: 0;
  display: inline-block;
  margin-left: 1rem;
`;

const Line = styled.span`
  width: 1rem;
  height: 100%;
  background: white;
  display: block;
  :nth-child(2n) {
    margin-left: 2rem;
    margin-right: auto;
  }
`;

export default Footer;
