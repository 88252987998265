import appReducer from './appReducer';
import gameReducer from './gameReducer';
import timeReducer from './timeReducer';
import { combineReducers } from 'redux';

export default combineReducers({
  time: timeReducer,
  game: gameReducer,
  app: appReducer,
});
