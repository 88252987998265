import {
  InstructionText,
  CommInstructionLogo,
  InstructionColor,
} from '../../../../lib/instructionSettings';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/TimeGauge';
import GamePresenterView from './GamePresenterView';
import GroupIdIndicator from './GroupIdIndicator';
import { Stage5Problem } from './data/data';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface GamePresenterProps {
  problem: Stage5Problem;
  searchId: number;
  onSkip: () => void;
}

const GamePresenter: React.FC<GamePresenterProps> = ({
  problem,
  searchId,
  onSkip,
}) => {
  const duration = 30;
  const navigate = useNavigate();
  return (
    <CommonBG>
      <TimeGauge
        timerKey={'stage:comm:' + problem.index}
        duration={duration}
        stageName={''}
        logo={CommInstructionLogo}
        color={InstructionColor}
        onSkip={onSkip}
        onRefreshDuration={(time: number) => {
          return;
        }}
        onBack={() => {
          navigate('/events/eventId/stages/');
          return;
        }}
      />
      <GamePresenterView problem={problem} />
      <GroupIdIndicator searchId={searchId} />
    </CommonBG>
  );
};

export default GamePresenter;
