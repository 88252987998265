import { parentURI } from '../../..';
import {
  InstructionColor,
  CommInstructionLogo,
  SpeccText,
} from '../../../lib/instructionSettings';
import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  onExit: (url: string) => void;
}

const FinishPage: React.FC<RuleInstrctionProps> = ({ onExit }) => {
  const { trainingId } = useParams<'trainingId'>();
  const stageSelectURI = `${parentURI}/trainings/${trainingId}/stages`;

  return (
    <CommonBG>
      <Rule
        mission={SpeccText.mission}
        description={SpeccText.description_finish}
        video={SpeccText.video}
        onStartText='終了する'
        onStart={() => onExit(stageSelectURI)}
        color={InstructionColor}
        logo={CommInstructionLogo}
      />
    </CommonBG>
  );
};

export default FinishPage;
