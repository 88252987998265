import Answer1Handler from '../../answers/Answer1Handler';

export const submitAnswer1 = (input: any) => {
  const inputAnswer = input.answer;
  // const { eventId } = input;

  // const zonedDate = getTime();
  const answer1Handler = new Answer1Handler();

  const conditions = [
    (left: string[], right: string[]) =>
      answer1Handler.collateAnswer1(left, right),
    (left: string[], right: string[]) =>
      answer1Handler.collateAnswer1(right, left),
    (left: string[], right: string[]) =>
      answer1Handler.calcAndCollateAnswer(left, right),
    (left: string[], right: string[]) =>
      answer1Handler.calcAndCollateAnswer(right, left),
    (left: string[], right: string[]) =>
      answer1Handler.calculateAnswer1(left, right),
  ];
  conditions.some(func => {
    const { left, right } = answer1Handler.unifyAnswer1(inputAnswer);
    return func(left, right);
  });
  return {
    answer: {
      achieved: answer1Handler.getAnswer1TargetList(),
      answer: inputAnswer,
    },
    targets: answer1Handler.getAnswer1Target(),
  };
};
