import Img1 from '../../../static/svg/Countdown_1.svg';
import Img2 from '../../../static/svg/Countdown_2.svg';
import Img3 from '../../../static/svg/Countdown_3.svg';
import ImgGO from '../../../static/svg/Countdown_GO.svg';
import CommonBG from '../../uiElements/CommonBG';
import * as React from 'react';
import styled from 'styled-components';

interface CountDownProps {
  onEnd?: () => void;
}
const CountDown: React.FC<CountDownProps> = props => {
  const [imgSrc, setImgSrc] = React.useState<string>('');

  React.useEffect(() => {
    setImgSrc(Img3);
    setTimeout(() => {
      setImgSrc(Img2);
    }, 1000);
    setTimeout(() => {
      setImgSrc(Img1);
    }, 2000);
    setTimeout(() => {
      setImgSrc(ImgGO);
    }, 3000);
    setTimeout(() => {
      props.onEnd?.();
    }, 4000);
  }, [props]);

  return <CommonBG>{imgSrc != '' && <Img src={imgSrc} />}</CommonBG>;
};

const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${248 * 1.2}px;
  height: ${470 * 1.2}px;
`;

export default CountDown;
