import CommonBG from '../../../uiElements/CommonBG';
import _TextWindow from '../../../uiElements/TextWindow';
import _Button from '../../../uiElements/button/SubButton';
import * as React from 'react';
import styled from 'styled-components';

interface GameBeforeStartProps {
  isPresenter: boolean;
  playerId: number;
  problemId: number;
  onStart: () => void;
}

const GameBeforeStart: React.FC<GameBeforeStartProps> = props => {
  const { isPresenter, playerId, problemId } = props;
  const setNum = problemId;
  const text =
    `第${setNum}問を開始します。` +
    (isPresenter
      ? 'あなたは「出題者」です。\n一斉にスタートボタンを押してください'
      : 'あなたは「解答者」です。\n一斉にスタートボタンを押してください');

  return (
    <CommonBG>
      <GameBeforeStartWrapper>
        <TextWindow bracket>
          <p>{text}</p>
          <Button size='large' color='positive' onClick={() => props.onStart()}>
            スタート
          </Button>
        </TextWindow>
      </GameBeforeStartWrapper>
    </CommonBG>
  );
};

const GameBeforeStartWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
`;

const Button = styled(_Button)`
  margin-top: 2rem;
`;

const TextWindow = styled(_TextWindow)`
  padding: 3.8rem 4rem;
`;

export default GameBeforeStart;
