import CommonBG from '../../../uiElements/CommonBG';
import Spinner from '../../../uiElements/Spinner';
import TextWindow from '../../../uiElements/TextWindow';
import GroupIdIndicator from './GroupIdIndicator';
import { GroupMemberItem } from './GroupInfo';
import * as React from 'react';
import styled from 'styled-components';

interface GamePollingProps {
  searchId: number;
  inactiveMemberDatas: GroupMemberItem[];
}

const GamePolling: React.FC<GamePollingProps> = ({
  searchId,
  inactiveMemberDatas,
}) => {
  const [showingInactiveMembers, setShowingInactiveMembers] =
    React.useState(false);

  React.useEffect(() => {
    const t = setTimeout(() => setShowingInactiveMembers(true), 5000);
    return () => clearTimeout(t);
  }, []);

  const text = '通信確認中...';
  return (
    <CommonBG>
      <GamePollingWrapper>
        <LoadingTextWindow bracket>
          <Spinner />
          <LoadingText>{text}</LoadingText>
        </LoadingTextWindow>
        {showingInactiveMembers && inactiveMemberDatas.length > 0 && (
          <Members>
            <InactiveMemberText>
              次のメンバーの通信が確認できません
            </InactiveMemberText>
            {inactiveMemberDatas.map(m => (
              <MemberItem key={m.uid}>{m.displayName}</MemberItem>
            ))}
          </Members>
        )}
      </GamePollingWrapper>
      <GroupIdIndicator searchId={searchId} />
    </CommonBG>
  );
};

const GamePollingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
`;

const LoadingTextWindow = styled(TextWindow)`
  display: flex;
  justify-content: center;
  line-height: 84px;
`;

const LoadingText = styled.div`
  margin-left: 2rem;
`;

const InactiveMemberText = styled.div`
  color: inherit;
  padding: 0.3rem 0;
  font-size: 2.3rem;
  letter-spacing: 0.06rem;
  text-align: center;
  white-space: pre-line;
  margin-top: 3rem;
  width: 50rem;
`;

const Members = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
`;

const MemberItem = styled.div`
  height: 2.3rem;
  line-height: 2.3rem;
  font-size: 2.3rem;
  margin-top: 2rem;
`;

export default GamePolling;
