import { instructionImgSrc } from '../../../lib/instructionImgSrc';
import useLocalStorage from '../../../lib/useLocalStorage';
import CommonBG from '../../uiElements/CommonBG';
import TextWindow from '../../uiElements/TextWindow';
import Button from '../../uiElements/button/SubButton';
import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const movie =
  'https://storage.googleapis.com/specc-ct-movie/99_SPECC%E5%96%B6%E6%A5%AD%E7%94%A8%E5%8B%95%E7%94%BB.mp4';
const IntervalExplanation = () => {
  const navigate = useNavigate();
  const match = useMatch('/events/:eventId/stages');
  const [eventId, setEventId] = useLocalStorage<string>(':eventId:', '');
  const toTop = () => {
    navigate('/events/' + eventId + '/stages');
  };

  return (
    <CommonBG>
      <CWrapper>
        <VideoWrapper>
          <video controls>
            <source src={movie} type='video/mp4' />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <Wrapper>
          <Text>
            <p>
              2つのステージの体験おつかれさまでした。もう一度Switchを体験してみましょう
            </p>
            <TopButton onClick={() => toTop()}>ステージ選択へ</TopButton>
          </Text>
        </Wrapper>
      </CWrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 4rem;
`;

const CWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: column;
`;

const Text = styled(TextWindow).attrs({
  bracket: true,
})`
  width: 60rem;
  max-width: 90vw;
  margin: 0 auto 0;
`;

const TopButton = styled(Button).attrs({
  variant: 'primary',
  size: 'large',
  color: 'positive',
})`
  margin: 2rem auto 1.4rem;
  display: block;
`;

const VideoWrapper = styled.div`
  width: 45%;
  margin: 4rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -3px;
  }
`;

export default IntervalExplanation;
