import { isStageDebug } from '../../../lib/env';
import useLocalStorage from '../../../lib/useLocalStorage';
import { useStagesFinished } from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import StageNumber from '../../uiElements/StageNumber';
import TextWindow from '../../uiElements/TextWindow';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link as _Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';

interface StageSelectorProps {}
const StageSelector: React.FC<StageSelectorProps> = () => {
  const stagesFinished = useStagesFinished();
  const match = useMatch('/events/:eventId/stages');

  const [eventId] = useLocalStorage<string>(':eventId:', '');
  return (
    <>
      <StageCaption>ステージを選んでください。</StageCaption>
      <StageList>
        {[1, 2, 3, 4, 5].map(i => (
          <StageListItem key={i} disabled={!isStageDebug && stagesFinished[i]}>
            <Link key={i} to={`/events/${eventId}/stages/${i}`}>
              <StageNumber stageNum={i as 1 | 2 | 3 | 4 | 5} />
            </Link>
          </StageListItem>
        ))}
      </StageList>
    </>
  );
};

const StageCaption = styled(TextWindow)`
  margin: 5rem 0;
  margin-bottom: 2.8rem;
  display: inline-block;
  width: 58rem;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 3px solid ${Colors.gray8};
    width: 10px;
    height: calc(100% - 6px);
    position: absolute;
    top: 0;
  }
  &:before {
    border-right: none;
    left: 0;
  }
  &:after {
    border-left: none;
    right: 0;
  }
`;

const StageList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 55rem;
  margin: 1rem auto;

  a {
    display: block;
    width: 84px;
    height: 80px;
  }
`;

interface StageItemProps {
  disabled: boolean;
}

const StageListItem = styled.li`
  pointer-events: ${(p: StageItemProps) => (p.disabled ? 'none' : 'initial')};

  svg {
    .cls-1 {
      transition: 0.2s;
      fill: ${(p: StageItemProps) =>
        p.disabled ? Colors.gray4 : Colors.primary};
    }
    .cls-2 {
      transition: 0.2s;
      fill: white;
    }
  }

  &:hover {
    svg {
      .cls-1 {
        fill: white;
        stroke: ${Colors.primary};
        stroke-width: 5px;
      }
      .cls-2 {
        fill: ${Colors.primary};
      }
    }
  }
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

export default StageSelector;
